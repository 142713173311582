import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
import _imports_0 from '../assets/mpQrCode.jpg';
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "width": "100%",
    "margin": "auto",
    "font-weight": "bold"
  }
};
const _hoisted_3 = {
  key: 1,
  style: {
    "text-align": "center",
    "margin-top": "10rem"
  }
};
import { wechat } from "@/api/wechat";
import { ElMessage, ElMessageBox } from "element-plus";
import { onBeforeMount, ref } from "vue";
import router from "@/router";
import WkLoaderBounce from "@/components/wk-loaders/wk-loader-bounce.vue";
import Cookies from "js-cookie";
import store from "@/vuex/store";
import { setToken, setUserId } from "@/utils/auth";
export default {
  __name: 'LoginCallback',
  props: {
    code: String,
    state: {
      type: String,
      default: null
    }
  },
  setup(__props) {
    onBeforeMount(() => {
      const state = props.state === "" ? null : props.state;

      // 如果存在code
      if (props.code) {
        wechat.callback({
          code: props.code,
          state: state
        }).then(res => {
          // 开始尝试登陆
          const result = res.data.data;
          if (res.data.code === 403) {
            ElMessage.warning('该微信号未绑定用户');
            return;
          } else if (res.data.code === 402) {
            console.log(res);
            loading.value = false;
            ElMessage.warning('请先关注公众号');
            // // 转跳关注
            // window.window.location.href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA5MDA3ODg2MA==&scene=124&bizpsid=0&subscene=0#wechat_redirect"
            return;
          } else if (res.data.code === 404) {
            // 如果是手机页面没有绑定用户则验证手机号码，则转跳到注册页面。
            if (state === null) {
              console.log("开始转跳注册页面");
              router.replace({
                name: 'mp-register',
                state: {
                  // 传递state给页面
                  unionid: result.unionId,
                  openId_mp: result.openid,
                  nickname: result.nickname,
                  headImgUrl: result.headImgUrl,
                  gender: result.sex === 0 ? 1 : 0
                }
              });
            } else {
              router.push('login'); // 转跳回登陆页面
            }
            return;
          }

          // 没有报错则直接获取登陆信息登陆
          ElMessage.success('登录成功!');
          setToken(result.token);
          setUserId(result.userId);

          // 转跳页面
          if (state) {
            router.push('admin');
          } else {
            router.push('mp/profile');
          }
        }).catch(error => {
          console.log(error);
          ElMessage.warning('登录时服务器出错！');
        });
      }
    });
    const props = __props;
    const loading = ref(true);
    return (_ctx, _cache) => {
      const _component_el_text = _resolveComponent("el-text");
      const _component_el_space = _resolveComponent("el-space");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_space, {
        direction: "vertical",
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(WkLoaderBounce), _createVNode(_component_el_text, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("正在验证身份...")])),
          _: 1
        })]),
        _: 1
      })])) : _createCommentVNode("", true), !loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_space, {
        direction: "vertical"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_text, {
          size: "large",
          type: "primary"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("长按二维码关注公众号")])),
          _: 1
        }), _createVNode(_component_el_text, {
          size: "large"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("关注后再扫一次二维码进行注册")])),
          _: 1
        })]),
        _: 1
      }), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "qr-code"
      }, [_createElementVNode("img", {
        alt: "zzchwxgzh",
        src: _imports_0
      })], -1))])) : _createCommentVNode("", true)]);
    };
  }
};