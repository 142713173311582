// 和上面一样 导入需要的东西 不要导入整个
import {createRouter, createWebHistory} from 'vue-router'	// 引入VueRouter
import Layout from "@/layout/LayoutIndex.vue"
import Login from "@/views/LoginPage.vue";
import CallBack from "@/views/LoginCallback.vue"
import Cookies from 'js-cookie'
import store from "@/vuex/store";
import {ref} from "vue";
import {getToken, getUserId} from "@/utils/auth";
import NProgress from "nprogress";
import {tools} from "@/utils/tools";



// 2. 定义一些路由
// eslint-disable-next-line no-unused-vars
let routes = [
    {
        path: '/admin',
        component: Layout,
        children: [
            {path: '', redirect: "/admin/home"},
            {path: 'about', component: () => import("@/views/AboutPage.vue")},
            {path: 'home', component: () => import("@/views/HomePage.vue")},
            {path: 'wk-student-editor/list', component: () => import("@/views/student/StudentListPage.vue")},
            {path: 'user/list', component: () => import("@/views/user/UserListPage.vue")},
            {path: 'user/role', component: () => import("@/views/user/RoleManagePage.vue")}
        ]
    },
    {path: '/login', name: 'login', component: Login},
    {path: '/', redirect: "/login"}
]
// eslint-disable-next-line no-unused-vars
let routesTest = [
    {path: '/login', name: 'login', component: Login},
    {path: '/mp/register', name: 'mp-register',
        component: ()=>import("@/views/mp/RegisterPage.vue"),
        meta: {
            title: '账号注册'
        }
    },
    {path: '/mp/login', name: 'LoginPageMp', component: ()=>import("@/views/mp/LoginPage.vue"),
        meta: {
            title: '登陆账号'
        }
    },
    {path: '/mp/profile', name: 'mp-profile', component: ()=>import("@/views/mp/ProfilePage.vue"),
        meta: {
            title: '个人资料'
        }
    },
    {path: '/callback', name: 'callback', component: CallBack,props: route => ({ code: route.query.code,state: route.query.state })},
    {path: '/', redirect: "/login"},
    {path: '', redirect: "/admin/home"},
    {
        path: '/:catchAll(.*)',
        hidden: true,
        component: () => import('@/views/error/ErrorPage404.vue')
    }

]


// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。
    history: createWebHistory(),
    routes: routesTest, // `routes: routes` 的缩写
})


const addRouters = (routers) => {

    routers.forEach(r => {
        router.addRoute(r)
    })

}

const generateRoutes = (to, from, next) =>{
    store.dispatch('permission/generateRoutes', getUserId()).then(result => {
        addRouters(result) // 动态添加可访问路由表
        next({...to, replace: true})

    }).catch(error => {
        console.log("生成路由失败",error);
    })
}


// 路由导航守卫
router.beforeEach((to, from, next) => {

    // 加载进度条
    NProgress.start()

    // 动态标题页面
    if (to.meta.title) {
        document.title = to.meta.title + " — 鸿校园信息管理系统"
    }


    if (getUserId() && getToken()) {
        // 登录过就不能访问登录界面，需要中断这一次路由守卫，执行下一次路由守卫，并且下一次守卫的to是主页'
        if (to.path === '/login') {
            next({path: '/admin'})
        }

        // 判断是否有用户信息，没有则拉取并保存。
        if (tools.isEmptyObject(store.getters.userInfo)){
            // 将路由添加到 store 中，用来标记已添加动态路由
            store.dispatch('user/getInfo').then(res => { // 拉取info
                if (store.getters.allocationRoute.length !== 0) {
                    // 放行
                    next()
                } else {
                    generateRoutes(to, from, next)
                }
            }).catch(err => {
                NProgress.done()
                console.log("获取用户信息失败",err);
            });
        }else {
            // 放行
            next()
        }

    } else {
        // 未登录时，注意 ：在这里也许你的项目不只有 login 不需要登录 ，register 等其他不需要登录的页面也需要处理
        const pathsPass = ["/mp/login","/mp/register","/callback"]
        if (pathsPass.includes(to.path) ) next() // 放行
        if (to.path !== '/login') {
            next({path: '/login'})
        } else {
            next()
        }
    }

})

router.afterEach(()=>{
    NProgress.done()
})

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export default router